// eslint-disable-next-line no-use-before-define
import React, {
  ReactNode, useContext, useEffect, useState,
} from 'react';

import {
  Flex,
  Stack,
  Box,
  Heading,
  Grid,
  Button,
  GridItem,
  VStack,
  HStack,
  Text,
} from '@chakra-ui/react';
import Head from 'next/head';
import router from 'next/router';
import { NextPageContext } from 'next';
import {
  GetCoursesDocument,
  IGetCoursesQuery,
  useGetBasketQuery,
  ICourseResult,
  IAttendanceType,
  ICourseSearchInput,
  ICourseGroup,
  useAddItemMutation,
  useRemoveItemMutation,
  GetBasketDocument,
  IDisplayStatus,
  GetSearchablesDocument,
  IGetSearchablesQuery,
  ILocationEnum,
  ITermResponse,
  GetTermsDocument,
  IGetTermsQuery,
  ITerm,
} from '../../lib/graphql';

import { initializeApollo } from '../../lib/apollo-client';
import BasketFooter from '../../components/basket/basketFooter';
import isMobile from '../../utils/isMobile';
import formatter from '../../utils/textUtils';
import ItemCard from '../../components/item/itemCard';
import formatMoney from '../../utils/money';
import ItemTags from '../../components/item/ItemTags';
import { getDateString } from '../../utils/dateUtils';
import { NavigationContext } from '../../lib/contexts/NavigationContext';
import Hero from '../../components/hero/hero';

interface ICourseListProps {
  courses: ICourseResult[]
  courseGroups: ICourseGroup[]
  terms: ITerm[]
  displayStatus: IDisplayStatus|null
}

const apolloClient = initializeApollo();

async function getSearchables():
Promise<{
  locations: Array<any>|undefined,
  danceTypes: Array<any>
}> {
  let locations = [];
  let danceTypes = [];

  try {
    const query = await apolloClient.query({
      query: GetSearchablesDocument,
    });
    const {
      getSearchables: { locations: locationsData, danceTypes: danceTypesData },
    } : IGetSearchablesQuery = query.data;
    locations = locationsData;
    danceTypes = danceTypesData;
  } catch (err) {
    console.log('ERROR');
    console.log(err);
  }
  return { locations, danceTypes };
}

async function getCourseList(searchData: ICourseSearchInput|undefined):
Promise<{
  courses: Array<ICourseResult>|undefined,
  courseGroups: Array<ICourseGroup>
}> {
  const searchValues = searchData || {};
  let courses: Array<ICourseResult>;
  let courseGroups: Array<ICourseGroup>;
  try {
    const query = await apolloClient.query({
      query: GetCoursesDocument,
      variables: { data: searchValues },
    });
    const { getCourses } : IGetCoursesQuery = query.data;
    const { courses: coursesRes, courseGroups: courseGroupsRes } = getCourses;

    courses = coursesRes;
    courseGroups = courseGroupsRes;
  } catch (err) {
    console.log('ERROR');
    console.log(err);
  }
  return { courses, courseGroups };
}

async function getTerms(displayStatus: string):
Promise<{
  terms: Array<ITermResponse>|undefined,
}> {
  let terms;
  try {
    const query = await apolloClient.query({
      query: GetTermsDocument,
      variables: { data: { displayStatus } },
    });
    const { getTerms: getTermData } : IGetTermsQuery = query.data;
    const { terms: termsData } = getTermData;

    terms = termsData;
  } catch (err) {
    console.log('ERROR');
    console.log(err);
  }

  return { terms };
}

const CourseList = ({
  courses = [],
  courseGroups = [], displayStatus,
  terms,
}: ICourseListProps): ReactNode => {
  const { isChildSite } = useContext(NavigationContext);

  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [filteredCourseGroups, setFilteredCourseGroups] = useState(courseGroups);

  const { data: basketData } = useGetBasketQuery();

  const [searchCriteria] = useState({});

  const isInBasket = (course) => {
    if (basketData?.getBasket?.basket?.items) {
      return Boolean(
        basketData?.getBasket?.basket?.items?.find((item) => item.course?.id === course.id),
      );
    }
    return false;
  };

  useEffect(() => {
    const fetchFilteredCourses = async () => {
      const resp = await getCourseList({ ...searchCriteria, displayStatus });
      setFilteredCourses([] || resp.courses);
      setFilteredCourseGroups(resp.courseGroups || []);
    };
    fetchFilteredCourses();
  }, [searchCriteria]);

  const [addItemMutation] = useAddItemMutation();
  const [removeItemMutation] = useRemoveItemMutation();

  function addItem(itemId: number, itemType: string) {
    addItemMutation({
      variables: { itemId, itemType },
      refetchQueries: [{ query: GetBasketDocument }],
    });
  }

  function removeItem(itemId: number, itemType: string) {
    removeItemMutation({ variables: { itemId, itemType } });
  }

  // const termInfo = [
  //   {
  //     label: '2023 Summer Term',
  //     start: new Date('2023-04-24'),
  //     end: new Date('2023-07-16')
  //   },
  //   {
  //     label: 'Summer camp',
  //     start: new Date('2023-07-29'),
  //     end: new Date('2023-08-12')
  //   },
  // ]

  const datesList = [

  ];

  const allItems = [...filteredCourseGroups, ...filteredCourses];

  const titlePrefix = isChildSite ? 'Child' : 'Adult';
  const heroOpacity = isChildSite ? '20%' : '75%';
  const heroImage = isChildSite ? '/images/hero/child_2.jpg' : '/images/hero/DSC_7582.jpg';
  const pageTitle = isChildSite ? 'Child' : 'Adult';

  return (
    <Flex flexDirection="column">
      <Head>
        <title>
          {pageTitle}
          {' '}
          Class Booking
        </title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Hero
        title={`${titlePrefix} Class Booking`}
        image={heroImage}
        backgroundOpacity={heroOpacity}
        mt={0}
      >
        <Flex alignItems="center" justifyItems="center" flexDirection="column" mb={12}>
          { terms?.map((term) => (
            <Box key={term.id} textAlign="center">
              <Heading as="h2" color="white">{term.name}</Heading>
              <Heading as="h3" mt={1} mb={4} size="md" color="white">
                {getDateString(new Date(term.startDate))}
                {' '}
                -
                {' '}
                {getDateString(new Date(term.endDate))}
              </Heading>
              { term.holidays.map((holiday) => (
                <Box textAlign="center">
                  <Heading as="h4" size="md" color="white">{holiday.name}</Heading>
                  <Heading as="h5" mt={1} mb={4} size="sm" color="white">
                    {getDateString(new Date(holiday.startDateTime))}
                    {' '}
                    -
                    {' '}
                    {getDateString(new Date(holiday.endDateTime))}
                  </Heading>
                </Box>
              ))}
            </Box>
          ))}

          { datesList.map((dateBlock) => (
            <Flex key={dateBlock.label} mt={3} alignItems={['center', 'start']} justifyItems={['center']} direction={['column', 'row']}>
              <Text mr={3}>
                {dateBlock.label}
              </Text>
              <Text>
                {getDateString(dateBlock.start)}
                {dateBlock.end && (
                  <span>
                    {' '}
                    -
                    {' '}
                    {getDateString(dateBlock.end)}
                  </span>
                )}

              </Text>
            </Flex>
          ))}
        </Flex>
      </Hero>
      <Flex width="100%" direction="column" alignItems="center">
        <Flex direction={['column', 'column', 'row']} width="90%" justifyContent="center">
          <Flex
            id="classList"
            flexDirection="column"
            width="100%"
            zIndex="1"
          >
            <Stack spacing={8}>
              <Grid
                gridTemplateColumns={['100%', '100%', 'repeat(2,50%)', 'repeat(3,300px)', 'repeat(4,300px)']}
                gap={5}
                justifyContent="center"
              >
                { allItems.map((item) => {
                  let itemPrice;
                  let itemTitle;
                  let itemDescription;
                  let itemImage;
                  let actionButton;
                  let imageOverlay;
                  let imagePosition;
                  let topBar;
                  let topBarColor;

                  switch (item.__typename) {
                    case 'CourseGroup':
                      itemTitle = item.name;
                      itemDescription = item.shortDescription;
                      itemImage = item.image || '';
                      itemPrice = `From ${formatMoney(item.minPrice)}`;
                      imageOverlay = (
                        <VStack height="100%" alignItems="baseline" flexDirection="column">
                          <HStack>
                            { item.courseTypes.map((type) => (
                              <Box key={`${item.id}${type}`}>
                                <ItemTags.CourseTypeBadge
                                  type={type}
                                  size="md"
                                  keyId={`${item.id}${type}`}
                                />
                              </Box>
                            ))}
                          </HStack>
                        </VStack>
                      );
                      imagePosition = `${item.imagePosition.X || 50}% ${item.imagePosition.Y}%`;
                      topBar = item.locations.reduce((agg, location) => {
                        if (location) {
                          if (agg.length > 0) {
                            return `${agg} / ${formatter.removeUnderscores(location)}`;
                          }
                          return agg + formatter.removeUnderscores(location);
                        }
                        return agg;
                      }, '');
                      topBarColor = item.locations.includes(ILocationEnum.Zoom) ? 'purple.200' : 'pink.500';
                      actionButton = <Button size="sm" w="100%" py={[2, 2, 'auto']} onClick={() => router.push(`/course/${item.id}`)} variant="solid" colorScheme="yellow" flex={1}>View Course</Button>;
                      break;
                    case 'OnlineCourse':
                    case 'StudioCourse':
                      itemTitle = item.name;
                      itemDescription = item.shortDescription;
                      itemImage = item.image;
                      itemPrice = formatMoney(item.price);
                      imageOverlay = (
                        <VStack height="100%" alignItems="baseline" flexDirection="column">
                          <HStack>
                            {/* <ItemTags.CourseTypeBadge type={item.__typename} size="md" keyId={item.id} /> */}
                          </HStack>
                        </VStack>
                      );
                      imagePosition = `${item.imagePosition.X || 50}% ${item.imagePosition.Y}%`;
                      topBar = formatter.capitalize(item.location);
                      topBarColor = item.attendanceTypes.includes(IAttendanceType.Children) ? 'pink.400' : 'violet.500';

                      // eslint-disable-next-line no-nested-ternary
                      actionButton = item.fullyBooked
                        ? <Button size="sm" w="100%" isDisabled variant="solid" colorScheme="red" flex={1}>Fully booked</Button>
                        : isInBasket(item) && item.attendanceTypes.includes(IAttendanceType.Adults)
                          ? (
                            <Button size="sm" w="100%" onClick={() => removeItem(item.id, 'course')} flex={1} variant="solid" colorScheme="pink">Remove</Button>
                          ) : (
                            <Button size="sm" w="100%" onClick={() => addItem(item.id, 'course')} variant="solid" colorScheme="yellow" flex={1}>Add to basket</Button>
                          );
                      break;
                    default:
                      break;
                  }

                  return (
                    <GridItem key={`bookingList${item.__typename + item.id}`}>
                      <ItemCard
                        title={itemTitle}
                        description={itemDescription}
                        image={itemImage}
                        ActionButton={actionButton}
                        ImageOverlay={imageOverlay}
                        imagePosition={imagePosition}
                        price={itemPrice}
                        topbar={topBar}
                        topBarColor={topBarColor}
                      />
                    </GridItem>
                  );
                })}
              </Grid>
            </Stack>
          </Flex>
        </Flex>
      </Flex>
      { isMobile() && <BasketFooter />}
    </Flex>
  );
};

CourseList.getInitialProps = async (ctx: NextPageContext) => {
  let query: ICourseSearchInput;
  let displayStatus;
  if (ctx.query.preview === IDisplayStatus.Preview) {
    displayStatus = IDisplayStatus.Preview;
    query = { displayStatus };
  }
  const { courses, courseGroups } = await getCourseList(query);
  const { locations, danceTypes } = await getSearchables();
  const { terms } = await getTerms(displayStatus);

  return {
    courses, courseGroups, displayStatus, locations, danceTypes, terms,
  };
};

export default CourseList;
