import { Badge } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import {
  IAttendanceType,
} from '../../lib/graphql';

type CourseBadgeProps = {
  type?: string
  title?: string
  size: 'lg'|'md'
  keyId: string|number
}

const CourseTypeBadge = ({
  type, size, title, keyId,
}: CourseBadgeProps): ReactElement => {
  if (title) return <Badge mt="auto" size={size} key={`CourseBadge${keyId}`}>{title}</Badge>;
  switch (type) {
    case 'OnlineCourse':
      return <Badge mt="auto" size={size} key={`OnlineCourse${keyId}`}>Online</Badge>;
      break;
    default:
      return <Badge mt="auto" size={size} key={`StudioCourse${keyId}`}>Studio</Badge>;
      break;
  }
};

type AttendanceTypeBadgeProps = {
  type: IAttendanceType
  size: 'lg'|'md'
  keyId: string
}

const AttendanceTypeTag = ({ type, size, keyId }: AttendanceTypeBadgeProps) => {
  switch (type) {
    case 'CHILDREN':
      return <Badge mt="auto" colorScheme="pink" size={size} key={`Children${keyId}`}>Children</Badge>;
    case 'ADULTS':
    default:
      return <Badge mt="auto" colorScheme="yellow" size={size} key={`Adults${keyId}`}>Adults</Badge>;
  }
};

const ItemTags = { AttendanceTypeTag, CourseTypeBadge };

export default ItemTags;
