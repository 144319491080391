const humanise = (text: string):string => text.split(/(?=[A-Z])/).join(' ');

const toPascalCase = (string: string):string => `${string}`
  .replace(new RegExp(/[-_]+/, 'g'), ' ')
  .replace(new RegExp(/[^\w\s]/, 'g'), '')
  .replace(
    new RegExp(/\s+(.)(\w+)/, 'g'),
    ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`,
  )
  .replace(new RegExp(/\s/, 'g'), '')
  .replace(new RegExp(/\w/), (s) => s.toUpperCase());

const removeUnderscores = (string: string):string => string.replace('_', ' ');

const capitalize = (string:string): string => removeUnderscores(string)
  .split(' ').map(
    (word) => capitalizeWord(word),
  ).join(' ');

const capitalizeWord = (string:string):string => string
  . charAt(0).toUpperCase() + string.toLowerCase().slice(1);

export default {
  humanise, toPascalCase, capitalize, removeUnderscores, capitalizeWord,
};
