// Return Date and Time
export const getDateTime = (date: Date): string => new Intl.DateTimeFormat('en-gb', {
  year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZone: 'UTC',
}).format(date);

// Return hh:mm for en-gb
export const getTimeFromDate = (date: Date): string => new Intl.DateTimeFormat('en-gb', {
  hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'UTC',
}).format(date);

// Return day of the week
export const getDayFromDate = (date: Date): string => new Intl.DateTimeFormat('en-gb', {
  weekday: 'long',
}).format(date);

// Return dd/mm/yyyy for en-gb
export const getDateString = (date: Date): string => new Intl.DateTimeFormat('en-gb', {
  year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC',
}).format(date);
